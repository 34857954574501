._1Iamx_k5plSdXFx4b20uVU {
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5000;
  min-width: 100%;
  min-height: 100%; }

._1KF574-wJgAo_HRaoq5Nza {
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  z-index: 5001;
  transition: 0.3s all;
  background: white;
  width: 500px;
  height: 260px; }
  ._1KF574-wJgAo_HRaoq5Nza.iP8TMDRqSgziDHYcw2oK0 {
    height: 350px; }
  ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo {
    background: white;
    position: relative; }
    ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo ._3ViUDAeK9BV_FiVPsgH9DB {
      padding: 15px;
      min-height: 50px; }
      ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo ._3ViUDAeK9BV_FiVPsgH9DB ._1UINBK8i6D2g28v3QVZ1k0 {
        float: right;
        cursor: pointer;
        font-weight: bold;
        width: 11px; }
      ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo ._3ViUDAeK9BV_FiVPsgH9DB .W44zalYAVLiIEjX_F8MBd {
        color: black;
        font-family: Nunito;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 30px; }
    ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo .cjcTqh-HHtSMF-yR8z9Zn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px; }

._1YgubpzMw4o8S6HyXAz8LD {
  width: 250px;
  height: 40px;
  font-weight: bold;
  margin-top: 1.05rem; }

._33WIilyGDK1ZxUFjGt20LO {
  width: 415px;
  height: 40px;
  padding-left: 10px;
  border-color: #000;
  border-width: thin;
  margin-bottom: 1.05rem; }

._3bp08nRz-S1Ju8eNFCcjG4 {
  border-color: #c30b24;
  margin-bottom: 0; }

._1uMexC6r0YWWU0hZnE6O9i {
  margin-top: 0; }

._1vFww2857U4NSe7geEj7Sh {
  color: #c30b24;
  font-size: 1rem;
  padding-top: 0.43rem;
  padding-bottom: 0.43rem;
  text-align: center; }

._3IBGxDr7Vsl8vENhJ9cAry {
  font-family: Nunito;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 35px; }

._23uelbAwjoIG9noQXvjaBy {
  text-align: center; }

@media (max-width: 768px) {
  ._1KF574-wJgAo_HRaoq5Nza {
    width: 100% !important;
    padding: 15px; }
    ._1KF574-wJgAo_HRaoq5Nza.iP8TMDRqSgziDHYcw2oK0 {
      height: 400px; }
    ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo .cjcTqh-HHtSMF-yR8z9Zn {
      padding: 0px; }
      ._1KF574-wJgAo_HRaoq5Nza ._3MW6OmiIP0tl28SyyYjlTo .cjcTqh-HHtSMF-yR8z9Zn ._33WIilyGDK1ZxUFjGt20LO {
        width: 80vw; } }

._2yXuz7wEBNebSwdwilsJ9p {
  text-align: center;
  margin: 50px 10px; }
