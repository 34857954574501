._3AQg9L-Ffw4RCUKDzb_Ytu {
  display: flex;
  align-items: center;
  justify-content: center; }

._28KCdJ70avlJaPltGtvmMR {
  margin-left: 5px; }

._3W0LLtDW8EPgQVH4x_QqOx {
  display: inline-block; }

.R3Z9bVig6Aez9u1JIQ-WC {
  font-size: 16px; }

._1e5ZGD3XyMAtHTYwK8y4K4 {
  border: 1px solid #eee; }

.kAbJ1ZvxtEo013pJ-POBS {
  display: flex;
  align-items: center; }
