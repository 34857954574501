._2jNFlq5ybAehh2XlDeLH5e {
  font-size: 14px;
  padding-top: 15px;
  text-align: center; }

._613uZ12onjrnla3lBGLwk {
  height: 200px;
  left: 50%;
  margin-left: -250px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 500px; }

._1tRKFIBv8keS8nLUj_soiS {
  color: #00bfff;
  font-weight: bold;
  font-size: 0.9rem; }

@media (min-width: 481px) {
  ._1tRKFIBv8keS8nLUj_soiS {
    font-size: 1.2rem; } }

@-webkit-keyframes HwR_vZ-TkGz8oO0YwW3xo /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes HwR_vZ-TkGz8oO0YwW3xo {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.HwR_vZ-TkGz8oO0YwW3xo {
  -webkit-animation: HwR_vZ-TkGz8oO0YwW3xo 2s linear infinite;
  -moz-animation: HwR_vZ-TkGz8oO0YwW3xo 2s linear infinite;
  -ms-animation: HwR_vZ-TkGz8oO0YwW3xo 2s linear infinite;
  -o-animation: HwR_vZ-TkGz8oO0YwW3xo 2s linear infinite;
  animation: HwR_vZ-TkGz8oO0YwW3xo 2s linear infinite; }

._2I35kqeYBvXMNWHBJsKKIT {
  width: 24px; }
